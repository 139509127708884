import styled from "@emotion/styled";
import { Divider, IconButton, MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import ReorderIcon from "@mui/icons-material/Reorder";
import { useRouter } from "next/router";
// import { Filters } from "shared/models/const/filters";
import CloseIcon from "@mui/icons-material/Close";
function OrderBy(props: any) {
  const [state, setState] = React.useState("");
  const { query } = useRouter();
  const options = [
    {
      id: "menor",
      name: "Menor a mayor precio",
    },
    {
      id: "mayor",
      name: "Mayor a menor precio",
    },
    {
      id: "recent",
      name: "Más recientes",
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    props.handle("", event.target.value);
  };

  useEffect(() => {
    if (query?.orderBy) {
      setState(query?.orderBy as string);
    } else {
      if (query?.recent) {
        setState("recent");
      } else {
        setState("");
      }
    }
  }, [query]);

  return (
    <ContentFilter>
      <Filter>
        <ReorderIcon />
        <Label>Ordenar por:</Label>
        <CustomTextField value={state} select onChange={handleChange}>
          {options.map((option) => (
            <CustomItem key={option.id} value={option.id}>
              {option.name}
            </CustomItem>
          ))}
        </CustomTextField>
        {state != "" && (
          <IconButton
            onClick={() => props.handle("orderBy", "")}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Filter>
    </ContentFilter>
  );
}

export default OrderBy;

const ContentFilter = styled.div`
  margin-bottom: 20px;
  width: 100%;
  .MuiTextField-root {
    * {
      border: none;
    }
  }
`;

const Label = styled.span`
  font-size: 1rem;
  color: var(--color-blue-main);
  font-weight: 600;
`;

const CustomItem = styled(MenuItem)`
  &.Mui-selected {
    background-color: var(--color-blue-main);
    color: var(--white);
    &:hover {
      background-color: var(--color-blue-main);
      color: var(--white);
    }
  }
  :hover {
    background-color: var(--color-blue-main);
    color: var(--white);
  }
`;

const CustomTextField = styled(TextField)`
  width: 220px;
  .MuiSelect-select.MuiSelect-outlined {
    color: var(--color-dark-gray);
    font-weight: 500;
  }
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  column-gap: 2px;
  svg {
    color: var(--color-blue-main);
  }
  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

import React, { useMemo } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Head from "next/head";
import { styled } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface BreadcrumbsProps {
  onChange?: (path: string) => void;
}

const Breadcrumbs = ({ onChange }: BreadcrumbsProps) => {
  const router = useRouter();

  // Extraer la ruta sin parámetros de consulta
  const pathWithoutQuery = useMemo(() => {
    return router.asPath.split("?")[0];
  }, [router.asPath]);

  // Construir breadcrumbs basados en la ruta
  const breadcrumbs = useMemo(() => {
    // Separar segmentos de la ruta y filtrar los vacíos
    const pathSegments = pathWithoutQuery.split("/").filter(Boolean);

    // Siempre empezar con Inicio
    const breadcrumbItems = [{ name: "Inicio", path: "/" }];

    // Construir rutas acumulativas
    let currentPath = "";
    pathSegments.forEach((segment) => {
      currentPath += `/${segment}`;

      // Formatear el nombre del segmento para que sea más legible
      const formattedName = segment
        .replace(/-/g, " ")
        .replace(/\b\w/g, (l) => l.toUpperCase()); // Capitalizar cada palabra

      breadcrumbItems.push({
        name: formattedName,
        path: currentPath,
      });
    });

    return breadcrumbItems;
  }, [pathWithoutQuery]);

  // JSON-LD para SEO
  const jsonLd = useMemo(() => {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbs.map((crumb, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: crumb.name,
        item: `${process.env.NEXT_PUBLIC_DOMAIN_URL || ""}${crumb.path}`,
      })),
    };
  }, [breadcrumbs]);

  // Función para manejar el cambio de ruta
  const onChangeRoute = (path: string) => (e: React.MouseEvent) => {
    // Si existe la función onChange, ejecutarla con la ruta
    // if (onChange) {
    //   e.preventDefault();
    //   console.log("path", path);
    //   onChange(path);
    //   // La navegación se manejará externamente por la función onChange
    // }
    // Si no hay onChange, la navegación ocurrirá normalmente por el Link
  };

  // No mostrar breadcrumbs en la página de inicio
  if (router.pathname === "/") return null;

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
      </Head>

      <BreadcrumbNav aria-label="breadcrumb">
        <BreadcrumbList>
          {breadcrumbs.map((crumb, index) => (
            <BreadcrumbItem
              key={`${crumb.path}-${index}`}
              isLast={index === breadcrumbs.length - 1}
            >
              {index > 0 && (
                <Separator aria-hidden="true">
                  <ArrowForwardIosIcon fontSize="small" />
                </Separator>
              )}

              {index === 0 ? (
                <BreadcrumbLink
                  href={crumb.path}
                  // passHref
                  // shallow={true}
                  // legacyBehavior
                >
                  {/* <a
                   onClick={onChangeRoute(crumb.path)}> */}
                  <HomeIconWrapper>
                    <HomeIcon fontSize="small" />
                  </HomeIconWrapper>
                  <span>{crumb.name}</span>
                  {/* </a> */}
                </BreadcrumbLink>
              ) : index === breadcrumbs.length - 1 ? (
                <CurrentPage aria-current="page">{crumb.name}</CurrentPage>
              ) : (
                <BreadcrumbLink
                  href={crumb.path}
                  // shallow={true}
                  // passHref
                  // legacyBehavior
                >
                  {/* <a 
                  onClick={onChangeRoute(crumb.path)}
                  > */}
                  {crumb.name}
                  {/* </a> */}
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          ))}
        </BreadcrumbList>
      </BreadcrumbNav>
    </>
  );
};

export default Breadcrumbs;

// Estilos con Material UI styled
const BreadcrumbNav = styled("nav")({
  width: "100%",
  padding: "0 0 20px 0",
  fontFamily: "var(--font-family)",
});

const BreadcrumbList = styled("ol")({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  margin: 0,
  padding: 0,
  listStyle: "none",
});

interface BreadcrumbItemProps {
  isLast: boolean;
}

const BreadcrumbItem = styled("li", {
  shouldForwardProp: (prop) => prop !== "isLast",
})<BreadcrumbItemProps>(({ isLast }) => ({
  display: "flex",
  alignItems: "center",
  color: isLast ? "var(--color-blue-main)" : "var(--color-gray-01)",
  fontSize: "0.875rem",
  fontWeight: 600,
  a: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    color: isLast ? "var(--color-blue-main)" : "var(--color-gray-01)",
    "&:hover": {
      color: "var(--color-secondary)",
      textDecoration: "underline",
    },
  },
}));

const Separator = styled("span")({
  margin: "0 8px",
  color: "var(--color-gray-01)",
  fontSize: "0.875rem",
  svg: {
    fontSize: "1rem",
    marginTop: "4px",
  },
});

const BreadcrumbLink = styled("a")({
  color: "var(--color-gray-01) !important",
  textDecoration: "none",
  transition: "color 0.2s ease",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    color: "var(--color-secondary)",
    textDecoration: "underline",
  },
  "& span": {
    marginLeft: "4px",
  },
});

const HomeIconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  fontSize: "1.1rem",
});

const CurrentPage = styled("span")({
  fontWeight: 600,
});

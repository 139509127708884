import React from "react";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";

import Menu from "./Menu";
import { useIntlPropertyTypes } from "shared/models/const/property-types";

import { DefaultGroup } from "shared/components/checbox-group";

function Content(props: any) {
  const { onChange, value = [] } = props;
  const translation = useTranslations("realestateList.bedroomsFilters");
  const roomOptions: any = [
    { label: translation("oneBedroom"), value: "1" },
    { label: translation("twoBedroom"), value: "2" },
    { label: translation("threeBedroom"), value: "3" },
    { label: translation("fourBedroom"), value: "4" },
    { label: translation("more"), value: ">4" },
  ];
  const handleCheckedStatus = (item: any) => {
    return value.includes(item);
  };

  return (
    <Wrapper>
      <Options>
        <StyledGroup
          manualChecked={true}
          handleCheckedStatusCustom={handleCheckedStatus}
          columns={12}
          items={roomOptions}
          row={false}
          onChange={onChange}
          values={value}
          name="bedrooms"
        />
      </Options>
    </Wrapper>
  );
}

function RoomFilters(props: any) {
  const { children, ...rest } = props;

  const Children = React.cloneElement(props.children);

  return <Menu content={<Content {...rest} />}>{Children}</Menu>;
}

export default RoomFilters;

const Wrapper = styled.div`
  background-color: #f7f6f4;
  border-radius: 10px;
  padding: 18px 13px 13px;
`;

const Options = styled.div`
  width: 100%;
`;

const StyledGroup = styled(DefaultGroup)`
  & .MuiFormControlLabel-root {
    margin-left: 0;
  }

  & .MuiCheckbox-root {
    padding: 5px;
    color: #1f3564;
  }

  & .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

import { useWidth } from "./use-width.hook";

function useMediaQuery() {
  const width = useWidth();
  return {
    isMobile: ["xs", "sm"].includes(width),
    isTablet: ["md"].includes(width),
    isLargeScreen: ["lg", "xl"].includes(width),
    isDesktop: ["lg"].includes(width),
    isLargeDesktop: ["xl"].includes(width),
  };
}

export default useMediaQuery;

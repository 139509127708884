import Head from "next/head";
import Filters from "shared/components-pages/proyectos/Filters";
import ProjectList from "shared/components-pages/proyectos/ProjectList";
import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { queryParserUrl } from "shared/models/functions/query-string-helper";
import {
  parseRealestateFiltersToUrl,
  parseUrlToRealestateFilters,
  validatePriceRange,
} from "shared/models/functions/realestate-filters-helper";
import { FormConfig } from "shared/components/buy-sell-filter/util/form.util";
import { CPagesPath } from "shared/models/const/pages-path";
import useCities from "custom-hooks/use-cities.hook";
import { seoPathname } from "shared/models/const/seo.const";
import useSectors from "custom-hooks/use-sector.hook";
import { debounce } from "lodash";

function adaptQueryParams(query: any, props: any) {
  if (typeof query.bedrooms == "string") {
    query.bedrooms = [query.bedrooms];
  }

  return query && !props.defaultFilters?.seoDynamic
    ? parseUrlToRealestateFilters(query)
    : {};
}

function RealestateList(props: any) {
  const { defaultFilters, collection } = props;
  const [title, setTitle] = useState<string | null>(null);
  const { data: cities } = useCities();
  const [cityId, setCityId] = useState<number>();
  const router = useRouter();

  // Ref para rastrear si el usuario ha interactuado con los filtros
  const hasUserInteracted = useRef(false);

  // Ref para evitar actualizar la URL durante la carga inicial
  const isFirstRender = useRef(true);

  const { data: sectors } = useSectors({
    idCity: cityId,
    onSuccess: (sectors: any[]) => {
      if (queryFilters.locations.length && queryFilters.sectorName) {
        const selectedSector = sectors.find(
          (sector) =>
            sector.description.toLowerCase() ==
            queryFilters.sectorName.replaceAll("-", " ")?.toLowerCase()
        );
        delete queryFilters.sectorName;
        queryFilters.sector = [selectedSector];
        setFilters({ ...queryFilters });
      }
    },
  });

  const { query } = queryParserUrl(router.asPath);
  const defaultState = {
    ...FormConfig.initialValues,
    ...defaultFilters.filters,
    ...adaptQueryParams(query, props),
  };

  const [queryFilters, setFilters] = useState(defaultState);

  // Función que marca que el usuario ha interactuado con los filtros
  const markUserInteraction = () => {
    hasUserInteracted.current = true;
  };

  const onFieldChange = (field: string, value: any) => {
    markUserInteraction(); // Marcar interacción
    const newFilters: any = { ...queryFilters, [field]: value };
    setFilters(newFilters);
  };

  const handleFiltersReset = () => {
    markUserInteraction(); // Marcar interacción
    setFilters(defaultState);
  };

  const onBusinessChange = (e: any) => {
    markUserInteraction(); // Marcar interacción
    let value;

    if (e.target) {
      value = e.target.value;
    } else if (typeof e === "string") {
      value = e;
    }

    if (value == "sale") {
      setTitle("Quiero Comprar");
    } else {
      setTitle("Quiero Alquilar");
    }

    onFieldChange("businessTypes", value);
  };

  // Modificar todas las demás funciones de change para marcar la interacción
  const onTypeChange = (e: any) => {
    markUserInteraction();
    const value: string = e.target.value;
    const typeProperty: Array<string> = [...queryFilters.typeProperty];

    if (typeProperty.includes(value)) {
      const index = typeProperty.indexOf(value);
      typeProperty.splice(index, 1);
    } else {
      typeProperty.push(value);
    }

    onFieldChange("typeProperty", typeProperty);
  };

  const onParkingLotsChange = (e: any) => {
    markUserInteraction();
    const value = e.target.value;

    if (value === queryFilters.parkings) {
      onFieldChange("parkings", "");
    } else {
      onFieldChange("parkings", value);
    }
  };

  const onBedroomsChange = (e: any) => {
    markUserInteraction();
    const value: string = e.target.value;
    const bedrooms: Array<string> = [...queryFilters.bedrooms];

    if (bedrooms.includes(value)) {
      const index = bedrooms.indexOf(value);
      bedrooms.splice(index, 1);
    } else {
      bedrooms.push(value);
    }
    onFieldChange("bedrooms", bedrooms);
  };

  const onBathroomsChange = (e: any) => {
    markUserInteraction();
    const value = e.target.value;

    if (value === queryFilters.bathrooms) {
      onFieldChange("bathrooms", "");
    } else {
      onFieldChange("bathrooms", value);
    }
  };

  const onSectorsChange = (e: any, data: any[]) => {
    markUserInteraction();
    const value: any = e.target.value;
    const newSector = data.find((x: any) => x.id == value);

    let sector: Array<string> = queryFilters.sector.map((x: any) => ({
      id: +x.id,
      description: x.description,
    }));

    if (e.target.checked == false) {
      sector = sector.filter((x: any) => x.id != +value);
    } else {
      sector.push(newSector);
    }
    setFilters({
      ...queryFilters,
      sector,
    });
  };

  const onCurrencyChange = (currencyType: any) => {
    markUserInteraction();
    setFilters({
      ...queryFilters,
      currencyType,
      amountMin: "",
      amountMax: "",
    });
  };

  const onAmountChange = (eventValue: string, field: "max" | "min") => {
    markUserInteraction();
    const value = eventValue ? +eventValue : null;
    let amountMax;
    let amountMin;
    let currencyType;
    if (typeof field == "undefined" && eventValue?.length === 3) {
      amountMax = +eventValue[1];
      amountMin = +eventValue[0];
      currencyType = eventValue[2];
    } else {
      amountMax = field === "max" ? value : queryFilters.amountMax;
      amountMin = field === "min" ? value : queryFilters.amountMin;
    }

    setFilters({
      ...queryFilters,
      currencyType,
      amountMax,
      amountMin,
    });
  };

  const onOrderByChange = (e: string, orderBy: string) => {
    markUserInteraction();
    const filters = { ...queryFilters, orderBy, recent: 1 };

    if (orderBy === "recent") {
      delete filters.orderBy;
    } else {
      delete filters.recent;
    }

    setFilters({ ...filters });
  };

  const handleAdvancedFilters = (values: any) => {
    markUserInteraction();
    setFilters({ ...queryFilters, ...values });
  };

  const onLocationsChange = (e: any, value: any) => {
    markUserInteraction();
    const locations: Array<any> = value;
    if (locations.length == 0) {
      setFilters({ ...queryFilters, sector: [], locations });
      return;
    }
    onFieldChange("locations", locations);
  };

  /**
   * Update selected city and sector filters if applicable.
   * This effect is triggered when the cities list is fetched, if sectorName is part of the query filters
   * the sector filter is updated with the corresponding sector object.
   */
  useEffect(() => {
    // Update selected city filter
    if (
      router.pathname === seoPathname &&
      cities?.length &&
      queryFilters.cityName &&
      !hasUserInteracted.current // Solo ejecutar durante la inicialización
    ) {
      const collator = new Intl.Collator("es", { sensitivity: "base" });
      queryFilters.locations = cities.filter(
        (city) =>
          collator.compare(
            city.description,
            queryFilters.cityName.replaceAll("-", " ")
          ) == 0
      );

      // Update selected sector filter
      if (queryFilters.sectorName && queryFilters.locations.length)
        setCityId(queryFilters.locations[0].id);

      setFilters({ ...queryFilters });
    }
  }, [cities]);

  const getActualUrl = (filters: any) => {
    const query = parseRealestateFiltersToUrl(filters);
    if (router.asPath.includes("/propiedades/comprar")) {
      return "/propiedades/comprar" + "?" + query;
    } else if (router.asPath.includes("/propiedades/alquilar")) {
      return "/propiedades/alquilar" + "?" + query;
    } else if (router.asPath.includes("/collection/propiedades")) {
      return "/collection/propiedades" + "?" + query;
    } else {
      return "/propiedades" + "?" + query;
    }
  };

  // Efecto para marcar que el primer renderizado ha terminado
  useEffect(() => {
    if (isFirstRender.current && router.isReady) {
      // Esperar un poco para asegurar que todos los datos estén cargados
      const timer = setTimeout(() => {
        isFirstRender.current = false;
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [router.isReady]);

  const ObservableUrlFilters = debounce((filters: any) => {
    // Solo actualizar URL si:
    // 1. No es el primer renderizado
    // 2. El usuario ha interactuado con los filtros
    if (!isFirstRender.current && hasUserInteracted.current) {
      const newUrl = getActualUrl(filters);
      if (newUrl && router.asPath !== newUrl) {
        router.push(newUrl, undefined, { shallow: true });
      }
    }
  }, 300); // Aumentar ligeramente el debounce para mejor UX

  useEffect(() => {
    ObservableUrlFilters(queryFilters);
  }, [JSON.stringify(queryFilters)]);

  const updateFilters = (filters: any) => {
    markUserInteraction(); // Marcar interacción
    setFilters({ ...queryFilters, ...filters });
  };
  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <Filters
        filters={queryFilters}
        onBusinessChange={onBusinessChange}
        onTypeChange={onTypeChange}
        onParkingLotsChange={onParkingLotsChange}
        onBedroomsChange={onBedroomsChange}
        onSectorsChange={onSectorsChange}
        onBathroomsChange={onBathroomsChange}
        handleAdvancedFilters={handleAdvancedFilters}
        onCurrencyChange={onCurrencyChange}
        onAmountChange={onAmountChange}
        onLocationsChange={onLocationsChange}
        collection={collection}
        handleFiltersReset={handleFiltersReset}
      />
      <ProjectList
        {...defaultFilters}
        filters={queryFilters}
        updateFilters={updateFilters}
        isCollection={collection}
        orderByFiltersHandler={onOrderByChange}
      />
    </>
  );
}

export default RealestateList;

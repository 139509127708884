import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import Menu from "./Menu";
import { useRouter } from "next/router";
import { queryParserUrl } from "shared/models/functions/query-string-helper";

import { DefaultGroup } from "shared/components/checbox-group";
import { DefaultTextField } from "shared/components/text-field";
import { useTranslations } from "next-intl";
import useSectors from "custom-hooks/use-sector.hook";
import {
  SectorItem,
  SectorListItem,
} from "@interfaces/response/sector.interface";

function Content(props: any) {
  const { onChange, value, sectorsCheckedList, queryFilters } = props;
  const router = useRouter();
  const filters: any = queryParserUrl(router.asPath);
  const idCity = getIdCity(filters, queryFilters);
  const [sectorList, setSectorList] = useState<SectorListItem[]>([]);
  const [checkedList, setCheckedList] = useState<any>([]);
  const translations = useTranslations("realestateList");

  const { data, isLoading } = useSectors({
    idCity,
    onSuccess: (data) => ParseSectors(data),
  });

  const filterSectors = useCallback(
    (search: string) => {
      if (!data?.data) return;
      let sectors = data.data;

      if (!!search) {
        sectors = data.data.filter((x) =>
          x.description.toLowerCase().includes(search.toLowerCase())
        );
      }

      ParseSectors(sectors);
    },
    [data]
  );

  useEffect(() => {
    if (data?.data) ParseSectors(data.data);
  }, []);

  const ParseSectors = useCallback((sectors: SectorItem[]) => {
    const collator = new Intl.Collator("es", { sensitivity: "base" });
    setSectorList(
      sectors
        .filter((x) => x)
        .map((sector) => ({
          value: sector?.id,
          label: sector?.description,
          id: sector?.id,
          checked:
            checkedList.find(
              (checked: any) =>
                checked?.id == sector?.id || checked?.value == sector?.id
            ) ||
            collator.compare(
              sector.description,
              queryFilters.sectorName?.replaceAll("-", " ")
            ) == 0,
        }))
    );
  }, []);

  useEffect(() => {
    setCheckedList(sectorsCheckedList);
  }, [sectorsCheckedList]);

  if (!data?.data && !idCity) {
    return (
      <Wrapper>
        <MessageResults>
          <a href="#locations" className="aquí_sector">
            {translations("sectorFilter.text1")}
            &nbsp;<LinkStyle>{translations("sectorFilter.text2")}</LinkStyle>
          </a>
        </MessageResults>
      </Wrapper>
    );
  }

  if (isLoading || !data?.data) {
    return (
      <Wrapper>
        <MessageResults>Cargando</MessageResults>
      </Wrapper>
    );
  }

  const handleCheckedStatus = (item: any) => {
    if (Array.isArray(value)) {
      return value.filter((x) => x).find((l: any) => l?.id == item);
    }
    return undefined;
  };

  return (
    <Wrapper>
      <Options>
        <ContentSearch>
          <CustomDefaultTextField
            placeholder="Buscar ..."
            name="search"
            onChange={(e) => filterSectors(e.target.value)}
          ></CustomDefaultTextField>
        </ContentSearch>
        <StyledGroup
          manualChecked={true}
          handleCheckedStatusCustom={handleCheckedStatus}
          columns={12}
          items={sectorList}
          row={false}
          onChange={(e: any) => onChange(e, data?.data)}
          values={value}
          name="sector"
        />
      </Options>
    </Wrapper>
  );
}

function SectorFilters(props: any) {
  const { children, ...rest } = props;

  const Children = React.cloneElement(props.children);

  return <Menu content={<Content {...rest} />}>{Children}</Menu>;
}

export default SectorFilters;

const ContentSearch = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background-color: #f7f6f4;
  padding: 10px 5px;
  z-index: 1000;
`;

const CustomDefaultTextField = styled(DefaultTextField)`
  &.MuiInput-root {
    padding: 5px 23px !important;
  }
`;

const MessageResults = styled.span`
  color: var(--color-blue-main);
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  width: 100%;
  display: inline-block;
`;
const LinkStyle = styled.span`
  color: var(--color-secondary);
  text-decoration: underline;
`;
const Wrapper = styled.div`
  background-color: #f7f6f4;
  border-radius: 10px;
  padding: 18px 13px 13px;
  min-width: 200px;
`;

const Options = styled.div`
  width: 100%;
  display: block;
  overflow: auto;
  max-height: 200px;
`;

const StyledGroup = styled(DefaultGroup)`
  & .MuiFormControlLabel-root {
    margin-left: 0;
  }

  & .MuiCheckbox-root {
    padding: 5px;
    color: #1f3564;
  }

  & .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

const getIdCity = (filters: any, queryFilters: any) => {
  if (filters.query?.locations) {
    return filters.query?.locations[0].split("&")[0].split("-")[1];
  } else if (queryFilters?.locations[0]?.id) {
    return queryFilters?.locations[0].id;
  }

  return null;
};
